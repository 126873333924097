import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

// Services
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';
import EditCustomerModal from 'components/customer/EditCustomerModal';

//-----------------------------------------------------------------

class ListCustomers extends React.Component {

    constructor(props) {
        super(props);

        this.editCustomerModalRef = React.createRef();

        this.state = {
            customers: []
        };
    }
    
    componentDidMount() {
        this.props.fns.setTitle('Customers');

        // Load from querystring
        const qs = queryString.parse(this.props.history.location.search);
        if (qs.customerID) {
            this.open({ id: qs.customerID });
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.type != oldProps.type) {
            this.search(this.state.query);
        }
    }

    async add() {
        await this.editCustomerModalRef.current.open({
            cameFrom: 'customers'
        });
        this.search(this.state.query);
    }

    async open(c) {
        let propertyID;
        
        if (c.property) {
            propertyID = c.property.id;
        }
        
        await this.editCustomerModalRef.current.open({
            id: c.id,
            propertyID,
            cameFrom: 'customers'
        });
        this.search(this.state.query);
    }

    async search(query) {
        this.setState({ query });
        const nonce = `${Math.random()}`;
        const response = await API.call('search/customer-property', {
            query,
            isArchived: (this.props.type != 'active'),
            nonce
        });
        if (response.nonce == nonce) {
            this.setState({
                customers: response.results
            });
        }
    }

    async showAll() {
        this.setState({ isLoading: true });
        const nonce = `${Math.random()}`;
        const response = await API.call('search/customer-property', {
            query: '',
            isArchived: (this.props.type != 'active'),
            showAll: true,
            nonce
        });
        if (response.nonce == nonce) {
            this.setState({
                customers: response.results
            });
        }
        this.setState({ isLoading: false });
    }

    exportToExcel() {
        API.call('customer/export-excel', {
            isDownload: true
        });
    }

    //----------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;
        const { type } = this.props;

        if (isLoading) {
            return (<Loader />);
        }
        
        return (<>

            <section className="control-panel">

                <div className="control-panel-component search-box">
                    <SearchBox
                        className="form-control"
                        placeholder="Search..."
                        minLength={2}
                        autoFocus={true}
                        searchDelay={250}
                        search={(query) => this.search(query)}
                        clear={() => this.setState({ customers: [] })}
                        onClickResult={result => this.props.history.push(`/customer/${result.entityID}`)}   
                    />
                </div>

                <div className="control-panel-component ms-auto">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {type == 'active' ? 'Active' : 'Archive'}
                        </button>
                        <ul className="dropdown-menu">
                            {type == 'active' &&
                                <li>
                                    <Link className="dropdown-item" to="/customers/archive">Archive</Link>
                                </li>
                            }
                            {type == 'archive' &&
                                <li>
                                    <Link className="dropdown-item" to="/customers/active">Active</Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                {type != 'archive' &&
                    <div className="control-panel-component">
                        <button type="button" className="ms-auto btn btn-primary" onClick={() => this.add()}>
                            New customer
                        </button>
                    </div>
                }

                <div className="control-panel-component">
                    <button type="button" className="ms-auto btn btn-secondary" onClick={() => this.exportToExcel()}>
                        Export all
                    </button>
                </div>

            </section>

            <section>

                {this.renderTable()}

            </section>

            <EditCustomerModal
                {...this.props}
                ref={this.editCustomerModalRef}
            />

        </>);
    }

    renderTable() {
        const {
            isLoading,
            customers,
            query
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        // Flatten list
        let customersFlattened;
        if (customers && customers.length > 0) {
            customersFlattened = [];
            customers.forEach(c => {
                if (!ReferenceHelpers.isIndividual(c.customerType)) {
                    customersFlattened.push({
                        ...c
                    });
                }
                c.properties.forEach(p => {
                    const customerAndProperty = {
                        ...c,
                        isProperty: true,
                        property: { ...p }
                    };
                    customersFlattened.push(customerAndProperty);
                });
            });
        }

        return (<>
            <SuperTable
                className="customers-table table clickable table-bordered clickable mb-0"
                rows={customersFlattened || []}
                keyAccessor={c => `${c.id}-${c.property ? c.property.id : 'company'}`}
                cols={{
                    name: {
                        label: 'Name',
                        getValue: (colInfo, customer) => {
                            const lines = [
                                customer.name,
                                customer.companyName ? 'Company: ' + customer.companyName : ''
                            ];
                            return lines.filter(l => !!l).map((l, index) =>
                                <div key={index}>{TextHelpers.formatHighlightedText(l)}</div>
                            );
                        }
                    },
                    reference: {
                        label: 'Unique Ref',
                        getValue: (colInfo, c) => {
                            return TextHelpers.formatHighlightedText(c.reference);
                        }
                    },
                    address: {
                        label: 'Property',
                        getValue: (colInfo, c) => {
                            if (c.property) {
                                return TextHelpers.formatHighlightedText(c.property.address);
                            } else {
                                return 'Multiple properties';
                            }
                        }
                    },
                    lastApptDate: {
                        label: 'Last Appt',
                        type: 'date',
                        getValue: (colInfo, c) => {
                            if (c.property && c.property.lastApptDate) {
                                return moment(c.property.lastApptDate).format('DD/MM/YYYY');
                            }
                        }
                    },
                    nextApptDate: {
                        label: 'Next Appt',
                        type: 'date',
                        getValue: (colInfo, c) => (c.property && c.property.nextApptDate ? moment(c.property.nextApptDate).format('DD/MM/YYYY') : '')
                    }
                }}
                onClick={(c, e) => this.open(c)}
                emptyText={query && customersFlattened && customersFlattened.length == 0 ?
                    'No matching customers or properties' : 'Please enter a search query'}
            />
            {!customersFlattened && 
                <button type="button" className="btn btn-primary list-all-customers-btn" onClick={() => this.showAll()}>
                    List all customers
                </button>
            }
        </>);
    }
}

export default ListCustomers;