import React from 'react';

import TextHelpers from 'helpers/TextHelpers';

class SearchBox extends React.Component {

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.focus = this.focus.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {};
        this.dataObj = {};
    }

    componentDidMount() {
        if (this.props.defaultQuery) {
            this.onChange(this.props.defaultQuery);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.searchTimeout);
        clearTimeout(this.blurTimeout);
    }

    onChange(query) {
        this.setState({ query });
        if (this.props.preProcessQuery) {
            query = this.props.preProcessQuery(query);
        }
        clearTimeout(this.searchTimeout);
        if (query && query.length >= this.props.minLength) {
            if (this.oldQuery !== query) {
                this.searchTimeout = setTimeout(() => {
                    this.props.search(query, (results) => {
                        this.setState({ results });
                    }, this.dataObj);
                }, this.props.searchDelay);
                this.oldQuery = query;
            }
        } else {
            this.setState({ results: null });
            this.oldQuery = null;
            if (this.props.clear) {
                this.props.clear();
            }
        }
    }

    onFocus() {
        this.setState({ isActive: true });
        if (this.inputRef.current) {
            this.inputRef.current.setSelectionRange(0, this.inputRef.current.value.length);
        }
    }

    onBlur(e) {
        clearTimeout(this.blurTimeout);
        this.blurTimeout = setTimeout(() => {
            this.setState({ isActive: false });
            if (this.props.onBlur) this.props.onBlur(e);
        }, this.props.blurDelay);
    }

    clearSearch() {
        this.setState({
            query: '',
            results: null
        });
    }

    focus() {
        this.blurTimeout = setTimeout(() => {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
                this.onFocus();
            }
        }, this.props.blurDelay + 1);
    }

    render() {
        const {
            query,
            results,
            isActive
        } = this.state;
        const info = {
            query,
            isActive,
            onInputChange: e => this.onChange(e.target.value),
            clearSearch: this.clearSearch,
            focus: this.focus,
            onClickResult: this.props.onClickResult
        };
        return this.props.renderWrapper(
            <input
                ref={this.inputRef}
                className={this.props.className}
                id={this.props.id}
                name={this.props.name}
                style={this.props.style}
                autoFocus={this.props.autoFocus}
                autoComplete="off"
                placeholder={this.props.placeholder}
                value={query || ''}
                onChange={e => this.onChange(e.target.value)}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
            />,
            results && results.map((result, index) => this.props.renderResult(result, index, info)),
            info,
            this.props.renderExtras(query, results),
            this.props.renderInputExtras(query, results)
        );
    }

}

SearchBox.defaultProps = {
    placeholder: 'Start typing to search...',
    searchDelay: 50,
    blurDelay: 150,
    minLength: 1,
    preProcessQuery: (query) => TextHelpers.simplifySearchString(query),
    search: (query, setResults) => { console.log('search property not specified') },

    renderExtras: (query, results) => null,
    renderInputExtras: (query, results) => null,

    renderWrapper: (input, results, info, extras, inputExtras) => <>
        <div className="search">
            <div className="search-input-container">
                {input}
                {inputExtras}
            </div>
            {results &&
                <div className="results active">
                    {results}
                    {extras}
                    {results.length == 0 &&
                        <div className="no-results">
                            No results
                        </div>
                    }
                </div>
            }
        </div>
    </>,
    
    renderResult: (result, index, info) => {
        debugger;
        return (
            <div key={index} className={`result ${result.isProperty ? 'property' : 'customer'}`} onClick={() => info.onClickResult(result)}>
                <div className="details">
                    <div className="name">
                        {TextHelpers.formatHighlightedText(result.name)}
                    </div>
                    {result.companyName &&
                        <div className="company-name">
                            {TextHelpers.formatHighlightedText(result.companyName)}
                        </div>
                    }
                    <div className="address">
                        {result.property ?
                            TextHelpers.formatHighlightedText(result.property.address) :
                            'Company'
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default SearchBox;


